import {ButtonHTMLAttributes} from 'react';

export default function IconButton(
  {
    type = 'button',
    className = '',
    disabled,
    children,
    ...props
  }: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      type={type}
      className={
        `
        h-10 
        w-10
        max-w-10 
        max-h-10
        transition-colors
        duration-200
        
        block 
        rounded-md
        bg-emerald-600
        
        text-sm
        text-center
        text-white
        
        p-2 
        font-semibold 
        shadow-sm 
        
        focus-visible:outline 
        focus-visible:outline-2 
        focus-visible:outline-offset-2 
        focus-visible:outline-slate-600
         
        hover:bg-emerald-400
        hover:text-emerald-900
        `
      }
      disabled={disabled}
    >
      {children}
    </button>
  );
}
